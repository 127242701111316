import React, { useEffect, useState, useRef } from "react";
import NearestAsteroid from "./nearestasteroid";
import 'react-dropdown/style.css';
import { Divider } from "@mui/material";

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    async componentDidMount() {

    }
    render() {
        return (
            <div>
                <div style={styles.container}>
                    <span style={styles.appTitle}> Easy Space </span>
                    <NearestAsteroid />

                </div>
                <Divider style={{ marginBottom: '10px' }} />
            </div>
        )
    }
}

const styles = {
    appTitle: {
        alignText: 'left',
        fontFamily: "Verdana, sans-serif",
        fontSize: '25px',
        display: 'flex',
        whiteSpace: 'nowrap',
        alignItems: 'center'
    },
    container: {
        display: "flex",
        flexDirection: 'row',
        padding: '10px',
    }
}

export default Header