import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, { useEffect, useState, useRef } from "react";


class ImageSlider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            images: props.images,
            index: 0
        }
        this.clickLeft = this.clickLeft.bind(this);
        this.clickRight = this.clickRight.bind(this);
    }

    clickRight() {
        if (this.state.index === this.state.images.length - 1) this.setState({ index: 0 })
        else this.setState({ index: this.state.index + 1 })

    }
    clickLeft() {
        if (this.state.index === 0) this.setState({ index: this.state.images.length - 1 })
        else this.setState({ index: this.state.index - 1 })
    }
    async componentDidMount() {
    }
    render() {
        return (
            <div>
                {this.state.images.length > 1 ?
                    <div >
                            <ArrowBackIosIcon onClick={this.clickLeft} style={styles.arrowLeft} />
                       
                            <ArrowForwardIosIcon onClick={this.clickRight} style={styles.arrowRight} />
                      
                    </div> : <div></div>
                }
                    
                <img style={styles.container} src={this.state.images[this.state.index]} />
               
            </div>

        )
    }
}

const styles = {
    arrowRight: {
        transform: 'scale(3)',
        stroke: 'white',
        strokeWidth: '.5',
        margin: "auto",
        position: "absolute",
        top: "0",
        bottom: "0",
        right: "50",
    },
    arrowLeft: {
        transform: 'scale(3)',
        stroke: 'white',
        strokeWidth: '.5',
        margin: "auto",
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "60",
        alignItems:'center'
    },
    container: {
        display: 'flex',
        width: '100%',
        height: '600px',
        objectFit: 'cover'
    }
}

export default ImageSlider