var { key } = require('./info')
var axios = require('axios')

export const getAstronomyPhotoOfTheDay = async () => {
    var url = 'https://api.nasa.gov/planetary/apod?api_key=' + key

    var data = await axios.get(url)
    return data.data
}

export const getNearestAsteroidForTheWeek = async (startDate, endDate) => {
    var url = `https://api.nasa.gov/neo/rest/v1/feed?api_key=${key}`
    if (startDate && endDate) url += `&start_date=${startDate}&end_date=${endDate}`
    var data = await axios.get(url)
    return data.data.near_earth_objects;
}

export const getMarsWeatherData = async () => {
    var url = 'https://api.nasa.gov/insight_weather/?feedtype=json&ver=1.0&api_key=' + key

    var data = await axios.get(url)
    return data.data
}

export const getCuriousityRoverPhotos = async (earth_date) => {
    var cameras = ["NAVCAM"]
    var url = `https://api.nasa.gov/mars-photos/api/v1/rovers/curiosity/photos?earth_date=${earth_date}&api_key=${key}`
    for (var camera of cameras) {
        url += `&camera=${camera}`
    }
    //url = 'https://api.nasa.gov/mars-photos/api/v1/manifests/curiosity?api_key=' + key
    var data = await axios.get(url)
    return data.data
}
export const getCuriousityRoverManifest = async () => {
    var url = 'https://api.nasa.gov/mars-photos/api/v1/manifests/curiosity?api_key=' + key
    var data = await axios.get(url).catch(function (e) {
        console.log(e)
    })
    return data.data
}
