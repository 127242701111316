import React, { useEffect, useState, useRef } from "react";
import {
  getCuriousityRoverManifest,
  getCuriousityRoverPhotos,
} from "../managers/apimanager";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ClipLoader from "react-spinners/ClipLoader";
import "react-awesome-slider/dist/styles.css";
import ImageSlider from "./customimageslider";
class CuriosityRover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableDates: [],
      photos: [
        "https://upload.wikimedia.org/wikipedia/commons/0/02/OSIRIS_Mars_true_color.jpg",
      ],
      date: null,
      loading: true,
    };
    this.dateSelected = this.dateSelected.bind(this);
  }
  async dateSelected(e) {
    this.setState({ loading: true }, async () => {
      console.log(e);
      var date = e.label;
      var photosAPI = await getCuriousityRoverPhotos(date);
      var photos = [];
      for (var photo of photosAPI.photos) {
        photos.push(photo.img_src);
      }
      var photoObj = {};
      for (var photo of photos) {
        if (photoObj[photo]) photoObj[photo]++;
        else photoObj[photo] = 1;
      }
      console.log(photoObj);
      this.setState({ date, photos, loading: false });
    });
  }
  findCommonElements3(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }
  parseManifest(manifest) {
    var cameras = ["NAVCAM"];
    var dates = [];
    for (var photos of manifest.photo_manifest.photos) {
      if (this.findCommonElements3(cameras, photos.cameras))
        dates.push(photos.earth_date);
    }
    dates.reverse();
    this.setState({ availableDates: dates, loading: false });
  }
  getLast10YearsDates() {
    const dates = [];
    const today = new Date();
    const startDate = new Date(today);
    startDate.setFullYear(today.getFullYear() - 10);

    for (
      let date = new Date(today);
      date >= startDate;
      date.setDate(date.getDate() - 1)
    ) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      dates.push(`${year}-${month}-${day}`);
    }

    return dates;
  }
  async componentDidMount() {
    // var manifest = await getCuriousityRoverManifest()
    // this.parseManifest(manifest)
    var availDates = this.getLast10YearsDates();
    this.setState({ availableDates: availDates, loading: false });
  }
  render() {
    console.log("Rendered", this.state);
    console.log(window);
    return (
      <div style={styles.container}>
        <span style={styles.header}>
          Select a Date, You Might Even See Curiosity's Selfies{" "}
        </span>
        {this.state.loading ? (
          <ClipLoader size={50} />
        ) : (
          <div>
            <Dropdown
              value={this.state.date}
              options={this.state.availableDates}
              onChange={this.dateSelected}
              placeholder="Select a date."
            />
            <ImageSlider images={this.state.photos} />
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  expand: {
    display: "flex",
    whiteSpace: "nowrap",
    textAlign: "right",
    width: "100%",
  },
  header: {
    display: "flex",
    textAlign: "left",
    fontFamily: "Verdana, sans-serif",
    marginBottom: "10px",
    marginTop: "10px",
    fontSize: "20px",
    paddingLeft: "10px",
  },
  container: {
    width: "100%",
    position: "relative",
  },
};

export default CuriosityRover;
