import logo from "./logo.svg";
import "./App.css";
import Dashboard from "./components/dashboard";
import AstronomyPhotoOfTheDay from "./components/apod";
function App() {
  return (
    <div className="App">
      <Dashboard />
      {/* <AstronomyPhotoOfTheDay /> */}
    </div>
  );
}

export default App;
