import React, { useEffect, useState, useRef } from "react";
import CuriosityRover from "./curiosityrover";
import AstronomyPhotoOfTheDay from "./apod";
import Header from "./header";
import Footer from "./footer";
import 'react-dropdown/style.css';
import { Divider } from "@mui/material";
import FadeIn from 'react-fade-in';
import Resources from "./resources"

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    async componentDidMount() {

    }
    render() {
        console.log(window.innerWidth)
        return (
            <div>
                <FadeIn>
                <Header />
                </FadeIn>
                <FadeIn>
                <div style={styles.card}>
                    <AstronomyPhotoOfTheDay />
                </div>
                <div style={{ marginBottom: '20px' }}></div>
                </FadeIn>
                <FadeIn>
                <div style={styles.card}>
                    <CuriosityRover />
                    
                </div>
                <div style={{ marginBottom: '20px' }}></div>
                </FadeIn>
                <FadeIn>
                <div style={styles.card}>
                    <Resources />
                </div>
                </FadeIn>
                <Divider style={{ marginTop: '10px' }} />
                <div style={styles.footer}>

                    <Footer />
                </div>

            </div>

        )
    }
}

const styles = {
    card: {
        display: "flex",
        width: '95%',
        margin: "0 auto",
        borderRadius: "10px",
        boxShadow: "0 0 3px #ccc",
        maxWidth:'800px'
    },
    footer: {
        padding: '10px'
    }
}

export default Dashboard