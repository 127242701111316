import React, { useEffect, useState, useRef } from "react";
import { getAstronomyPhotoOfTheDay } from "../managers/apimanager";
import "react-dropdown/style.css";
import Divider from "@mui/material/Divider";
import ClipLoader from "react-spinners/ClipLoader";

class AstronomyPhotoOfTheDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      url: undefined,
      text: undefined,
      title: undefined,
    };
  }

  async componentDidMount() {
    var APOD = await getAstronomyPhotoOfTheDay();
    console.log(APOD);
    this.setState({
      url: APOD.url,
      title: APOD.title,
      text: APOD.explanation,
      loading: false,
    });
  }
  render() {
    return (
      <div>
        {this.state.loading ? (
          <ClipLoader size={50} />
        ) : (
          <div>
            <span style={styles.header}>Astronomy Photo Of The Day</span>
            <Divider />
            {this.state.url.includes("youtube.com") ? (
              <span style={styles.info}>
                <a target="_blank" href={this.state.url}>
                  View Video
                </a>
              </span>
            ) : (
              <img
                style={styles.apodPhoto}
                src={this.state.url}
                alt="Astronomy Photo Of The Day"
              />
            )}
            <span style={styles.title}>{this.state.title}</span>
            <span style={styles.body}>{this.state.text}</span>
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  header: {
    display: "flex",
    textAlign: "left",
    fontFamily: "Verdana, sans-serif",
    marginBottom: "10px",
    marginTop: "10px",
    fontSize: "20px",
    paddingLeft: "10px",
  },
  title: {
    display: "flex",
    textAlign: "left",
    fontFamily: "Verdana, sans-serif",
    marginBottom: "10px",
    marginTop: "10px",
    fontSize: "17px",
    fontStyle: "italic",
    paddingLeft: "10px",
  },
  body: {
    display: "flex",
    textAlign: "left",
    fontFamily: "Verdana, sans-serif",
    fontSize: "13px",
    paddingLeft: "10px",
    marginBottom: "10px",
  },
  apodPhoto: {
    width: "100%",
  },
  info: {
    fontFamily: "Verdana, sans-serif",
    fontSize: "15px",
  },
};

export default AstronomyPhotoOfTheDay;
