import React, { useEffect, useState, useRef } from "react";
import 'react-dropdown/style.css';
import { Divider } from "@mui/material";

class Resources extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
      
    }
    render() {
        return (
            <div style = {styles.container}>
                <span style={styles.header}>Resources</span>
                <Divider style={{ marginBottom: '10px' }} />
                <span style={styles.info} ><a target="_blank" href="https://mars.nasa.gov/mars2020/participate/name-the-rover/"> How does NASA name their rovers?</a></span>
                <span style={styles.info} ><a target="_blank" href="https://www.iau.org/public/themes/naming/"> How asteroids get their names?</a></span>
                <span style={styles.info} ><a target="_blank" href="https://api.nasa.gov/"> Where does NASA provide data for an application like this?</a></span>
                <span style={styles.info} ><a target="_blank" href="https://mars.nasa.gov/mer/mission/science/objectives/#:~:text=The%20scientific%20objectives%20of%20the,clues%20to%20past%20water%20activity."> What is the purpose of the Mars rover missions?</a></span>

            </div>
        )
    }

}
const styles = {
    header: {
        display: "flex",
        textAlign: 'left',
        fontFamily: "Verdana, sans-serif",
        marginBottom: '10px',
        marginTop: '10px',
        fontSize: '20px',
        paddingLeft:'10px'
    },
    container:{
        display:'flex',
        flexDirection:'column',
        width:'100%',
        textAlign:'left'
    },
    info: {
        fontFamily: "Verdana, sans-serif",
        fontSize: '15px',
        display: "flex",
        paddingLeft:'10px',
        marginBottom:'5px'
    },
}

export default Resources