import React, { useEffect, useState, useRef } from "react";
import 'react-dropdown/style.css';

class Footer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    async componentDidMount() {

    }
    render() {
        return (
            <div>

                <div style={styles.container}>
                    <span style={styles.appTitle}> By Rahel Sosunov </span>

                </div>
            </div>
        )
    }
}

const styles = {
    appTitle: {
        fontFamily: "Verdana, sans-serif",
        fontSize: '12px',
    },
    container: {
        textAlign: 'right',
        width: '100%',
    }
}

export default Footer