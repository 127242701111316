import React, { useEffect, useState, useRef } from "react";
import { getNearestAsteroidForTheWeek } from "../managers/apimanager";
import 'react-dropdown/style.css';
import ClipLoader from "react-spinners/ClipLoader";

class NearestAsteroid extends React.Component {
    constructor(props) {
        super(props)
        var date = new Date()
        const offset = date.getTimezoneOffset()
        var startDate = date.getTime() - (offset * 60 * 1000)
        var endDate = startDate + 604800000
        startDate = new Date(startDate).toISOString().split('T')[0]
        endDate = new Date(endDate).toISOString().split('T')[0]
        console.log(startDate, endDate)
        this.state = {
            nearestAsteroid: null,
            date: startDate,
            loading: true,
            endDate
        }
    }

    async componentDidMount() {
        var asteroids = await getNearestAsteroidForTheWeek(this.state.date, this.state.endDate)
        if (!asteroids[this.state.date]) return true;
        asteroids = asteroids[this.state.date]
        //console.log(asteroids)
        var cleaned = []
        for (var asteroid of asteroids) {
            var obj = {
                absolute_magnitude_h: asteroid.absolute_magnitude_h,
                name: asteroid.name,
            }
            Object.assign(obj, asteroid.close_approach_data[0].miss_distance)
            Object.assign(obj, asteroid.close_approach_data[0].relative_velocity)
            cleaned.push(obj)
        }
        cleaned.sort((a, b) => a.miles - b.miles);
        this.setState({ nearestAsteroid: cleaned[0], loading: false })
    }
    render() {
        return (
            <div style={styles.container}>
                {this.state.loading ?
                    <ClipLoader size={20} /> :
                    <div>
                        <span style={styles.title}>Closest Asteroid Today, {this.state.date}</span>
                        {this.state.nearestAsteroid ?
                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                <span style={styles.body}>Name: {this.state.nearestAsteroid.name}</span>
                                <span style={styles.body}>Distance: {parseInt(this.state.nearestAsteroid.miles)} miles from Earth</span>
                            </div> :
                            <div>
                                <span style={styles.body}>No Asteroid Reported Close On This Day.</span>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

}
const styles = {
    container: {
        width: '100%',
        textAlign: 'right'
    },
    title: {
        fontFamily: "Verdana, sans-serif",
        fontSize: '15px',
    },
    body: {
        fontFamily: "Verdana, sans-serif",
        fontSize: '12px',
    },
}

export default NearestAsteroid